import React, { useEffect, useRef, useMemo } from 'react'
import UpdateFlexibleBenefitsSystem from '../../assets/images/base/update-flexible-benefits-system-access.jpg'
import Image from '../../components/common/Image'
import styled from '@emotion/styled'
import { mobile, tablet } from '../../utils/responsive-helper'

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageStyled = styled(Image)`
  height: 100vh;

  ${tablet} {
    max-width: 100%;
  }

  ${mobile} {
    height: 100%;
  }
`

const DELAY = 5 // second

const Disable = () => {
  const timeoutRef = useRef<any>()

  useEffect(() => {
    const redirectUrl = () => {
      timeoutRef.current = setTimeout(() => {
        window.open('https://buddyup-uat.zennite.co/login', '_self')
      }, DELAY * 1000)
    }

    redirectUrl()

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Layout>
      <ImageStyled src={UpdateFlexibleBenefitsSystem} />
    </Layout>
  )
}

export default Disable